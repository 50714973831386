import "@/globals.css";
import BaseHead from "@/components/Head/base";
import DeferredHead from "@/components/Head/deferred";
import { memo, useEffect } from "react";
import { ThemeProvider } from "next-themes";
import "react-toastify/dist/ReactToastify.css";
import dynamic from "next/dynamic";
import { Provider as ReduxProvider } from "react-redux";
import appStore from "../store";
import ErrorBoundary from "./ErrorBoundary";
import Script from "next/script";

const AppLayout = dynamic(() => import("@/layouts"));
const OffCanvas = dynamic(() => import("@/components/InitializeOnMount/OffCanvas"));
declare global {
  interface Window {
    googletag: { cmd: googletag.CommandArray; };
    google: any;
    gtag: any;
    pbjs: {
      que: any[];
      addAdUnits: Function;
      requestBids: Function;
      initAdserverSet: any;
      setTargetingForGPTAsync: Function;
      onEvent: Function;
      offEvent: Function;
      setConfig: Function;
      setBidderConfig: Function
      getEvents: () => { eventType: string }[];
      adServers: {
        dfp: {
          setTargetingForGPTAsync: Function;
          buildVideoUrl: Function;
        },
      },
    };
    dataLayer: any[];
    SpeechRecognition?: any;
    webkitSpeechRecognition?: any;
    mozSpeechRecognition?: any;
    msSpeechRecognition?: any;
    oSpeechRecognition?: any;
    Razorpay?: any;
    refreshTruereachAd?: Function;
    adsbygoogle?: any[];
    ImageCapture: any;
    appConfig: {
      [key: string]: any;
    };
  }
}

const DoubtnutApp = ({ Component, pageProps }: { Component: any; pageProps: any }) => {
  const initDD = async () => {
    const datadogRum = (await import("@datadog/browser-rum")).datadogRum;
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DD_APP_ID as string,
      clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN as string,
      site: "datadoghq.com",
      service: "webapp-next",
      env: process.env.NEXT_PUBLIC_DD_ENV || "dev",
      // Specify a version number to identify the deployed version of your application in Datadog 
      version: process.env.NEXT_PUBLIC_DD_VERSION || "1.0.0",
      sessionSampleRate: 0.5,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
  }

  const themeScript = () => {
    const getThemeVariant = () => {
      return localStorage.getItem("theme-variant") || "doubtnut";
    }
    const getTheme = () => {
      return localStorage.getItem("theme") || "light";
    }
    const themeVariant = getThemeVariant();
    const theme = getTheme();
    document.documentElement.setAttribute("data-variant", themeVariant);
    document.documentElement.setAttribute("class", theme);
  }

  useEffect(() => {
    window.appConfig = window.appConfig || {};
    initDD();
  }, []);

  return (
    <ErrorBoundary>
      <ReduxProvider store={appStore}>
        <ThemeProvider enableSystem={false} attribute="data-theme">
          <Script id="theme-variant-script" strategy="beforeInteractive" dangerouslySetInnerHTML={{ __html: `(${themeScript.toString()})()` }} />
          <BaseHead />
          <AppLayout>
            <div className="w-full">
              <Component {...pageProps} />
            </div>
            <OffCanvas />
            <DeferredHead {...pageProps} />
          </AppLayout>
        </ThemeProvider>
      </ReduxProvider>
    </ErrorBoundary >
  )
}

export default memo(DoubtnutApp);