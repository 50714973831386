import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "..";

// Type for our state
export interface SeminarState {
  id: string | null;
  title: string | null;
  isLive: boolean;
}

// Initial state
const initialState: SeminarState = {
  id: null,
  title: null,
  isLive: false,  
};

// Actual Slice
export const seminarSlice = createSlice({
  name: "seminar",
  initialState,
  reducers: {
    resetSeminarRegistrationIntent(state) {
      state.id = null;
      state.title = null;
      state.isLive = false;
    },
    updateSeminarRegistrationIntent(state, action) {
      state.id = action.payload.id;
      state.title = action.payload.title;
      state.isLive = action.payload.isLive;
    }
  },
});


export default seminarSlice.reducer;

export const selectSeminar = (state: AppState) => state.seminar;

export const {
  resetSeminarRegistrationIntent,
  updateSeminarRegistrationIntent
} = seminarSlice.actions;
