import { AnyAction, ListenerEffectAPI, ListenerMiddlewareInstance, Middleware, TypedAddListener, TypedStartListening, createListenerMiddleware } from "@reduxjs/toolkit";
import { setCheckoutMobile, setMobileReadOnly } from "./slices/checkout";
import { AppDispatch, AppState } from ".";

export const listenerMiddleware = createListenerMiddleware();

// Define typed startListening and addListener
export type AppStartListening = TypedStartListening<AppState, AppDispatch>;
export type AppAddListener = TypedAddListener<AppState, AppDispatch>;

const startAppListening = listenerMiddleware.startListening as AppStartListening;

startAppListening({
  predicate: (action: AnyAction, currentState: AppState, previousState: AppState) => {
    return !!currentState?.offCanvas?.data?.MOBILE_CONFIRMATION.mobile
      && (currentState?.offCanvas?.data?.MOBILE_CONFIRMATION.mobile !== previousState?.offCanvas?.data?.MOBILE_CONFIRMATION?.mobile);
  },
  effect: (action: AnyAction, listenerApi: ListenerEffectAPI<AppState, AppDispatch>) => {
    const state = listenerApi.getState();
    const mobile = state?.offCanvas?.data?.MOBILE_CONFIRMATION.mobile;
    listenerApi.dispatch(setMobileReadOnly(false));
    listenerApi.dispatch(setCheckoutMobile(mobile));
  },
});
