import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "..";

export interface ICheckoutData {
  mobile?: string;
  coupon_data?: {
    applicable_coupons: {
      coupon_title: string;
      amount: number;
      amount_saved: string;
    }[];
    code: string;
    coupon_amount: string;
    status: boolean;
  };
  title: string;
  subtitle: string;
  price: string;
  price_breakup: {
    title: string;
    amount: string;
  }[];
  payment_for: string;
  wallet_data?: {
    is_wallet_payment: boolean;
    wallet_cash_amount?: string;
    wallet_reward_amount?: string;
  };
  payment_id: string | number;
  status: string;
  recent_purchase: {
    text: string;
    image: string;
  }[];
}

// Type for our state
export interface CheckoutState {
  checkoutData?: ICheckoutData;
  mobile?: string;
  mobileReadOnly: boolean;
  useWallet: {
    use_wallet_reward: boolean;
    use_wallet_cash: boolean;
    selectedWallet: number[];
  };
  razorpay?: {
    createPayment: Function;
    emit: Function;
    getCardFeatures: (cardNumber: string) => Promise<{
      flows: {
        otp: boolean;
        recurring: boolean;
        iframe: boolean;
        emi: boolean
      };
      type: "credit" | "debit";
      issuer: string;
      network: "MasterCard" | "Visa" | "RuPay" | "American Express" | "Diners Club" | "Discover" | "JCB" | "Maestro" | "Unknown";
      cobranding_partner: any;
      country: string;
    }>;
    on: Function;
    off: Function;
    _payment?: {
      clear: Function;
    }
  };
  paidAmount?: number;
  checkoutModalVisible?: boolean;
}

// Initial state
const initialState: CheckoutState = {
  useWallet: {
    use_wallet_reward: false,
    use_wallet_cash: false,
    selectedWallet: [],
  },
  checkoutModalVisible: false,
  mobileReadOnly: false,
};

// Actual Slice
export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setCheckoutData(state, action) {
      state.checkoutData = action.payload;
      if (action.payload?.mobile) {
        state.mobile = action.payload.mobile;
      }
    },
    setCheckoutMobile(state, action) {
      state.mobile = action.payload;
    },
    setMobileReadOnly(state, action) {
      state.mobileReadOnly = action.payload;
    },
    setRazorpay(state, action) {
      state.razorpay = action.payload;
    },
    setUseWallet(state, action) {
      state.useWallet = { ...state.useWallet, ...action.payload };
      if (state.useWallet) {
        state.useWallet.selectedWallet = [];
        if (state.useWallet.use_wallet_reward) {
          state.useWallet.selectedWallet.push(1);
        }
        if (state.useWallet.use_wallet_cash) {
          state.useWallet.selectedWallet.push(2);
        }
      }
    },
    setPaidAmount(state, action) {
      state.paidAmount = action.payload;
    },
    setCheckoutModalVisible(state, action){
      state.checkoutModalVisible = action.payload;
    }
  },
});


export default checkoutSlice.reducer;

export const selectCheckoutData = (state: AppState) => state.checkout.checkoutData;
export const selectCheckoutMobile = (state: AppState) => state.checkout.mobile;
export const selectMobileReadOnly = (state: AppState) => state.checkout.mobileReadOnly;
export const selectRazorpay = (state: AppState) => state.checkout.razorpay;
export const selectUseWallet = (state: AppState) => state.checkout.useWallet;
export const selectPaidAmount = (state: AppState) => state.checkout.paidAmount;
export const selectModalVisible = (state: AppState) => state.checkout.checkoutModalVisible;

export const {
  setCheckoutData,
  setCheckoutMobile,
  setMobileReadOnly,
  setRazorpay,
  setUseWallet,
  setPaidAmount,
  setCheckoutModalVisible,
} = checkoutSlice.actions;
