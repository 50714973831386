import { WEB_API, BACKEND_API, DEEPLINK_HOST } from "@/config/constants";
import { apiClient } from "@/helpers/request";
import { IPro } from "@/pages/pro/interface";
import { ParsedUrlQuery } from "querystring";

export const getAdsData = (page: string, studentId: string, qid?: string) => {
  return apiClient(BACKEND_API, `/v2/web-landing/web-ads-info`, {
    query: {
      page,
      studentId,
      qid,
    },
  });
};

export const updateVVAlt = (params: any) => {
  if (params.ans_id === null) {
    params.answer_video = "text";
  }
  const x_sid = params.x_sid;
  delete params.x_sid;
  return apiClient(BACKEND_API, "/v3/answers/update-vvs-whatsapp", {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      x_sid,
      version_code: "2000",
      "Content-Type": "application/json",
    },
  });
};

export const studentIdCall = (
  uuid: string,
  pageUrl: string,
  fcmToken?: string
) => {
  return apiClient(WEB_API, "/api/v1/student/get-student-id", {
    method: "POST",
    body: JSON.stringify({
      uuid: uuid,
      page_url: pageUrl,
      platform: "mweb",
      fcmToken,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getLeadFormFields = () => {
  return apiClient(BACKEND_API, "/v1/web-lead/get-fields");
};

export const submitLeadForm = (data: any) => {
  return apiClient(BACKEND_API, "/v1/web-lead/submit", {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const getBoardResultsData = () => {
  return apiClient(
    BACKEND_API,
    "/v1/student/testimonials"
  );
};

export const getAdFreeWidgetData = (pageName: string): Promise<{ data: IPro }> => {
  return apiClient(
    BACKEND_API,
    `/v1/ads/go-ad-free-widget-web?page=${pageName}`
  );
};

export const getPaymentDetailsAllenOfflineEvent = (category: string, grade: string, studentId: string) => {
  return apiClient(BACKEND_API, `/v1/payment/allen-offline-event/${category}/${grade}/${studentId}`);
}

export const submitDetailsAllenOfflineEvent = (body: {
  name: string;
  mobile: string;
  class: string;
  category: string;
  city: string;
  generate_payment_link: boolean;
}) => {
  return apiClient(BACKEND_API, "/v1/payment/allen-offline-event", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body)
  });
}

export const submitDetailsAllenOfflineEventFeedback = (body: {
  name: string;
  class: string;
  category: string;
  overall_satisfaction: string;
  content_satisfaction: string;
  speaker_satisfaction: string;
  information_satisfaction: string;
  learn_more_about_allen: string;
  remarks: string;
}) => {
  return apiClient(BACKEND_API, "/v1/payment/allen-offline-event-feedback", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body)
  })
}

export const generateDeeplink = async (body: {
  deeplink: string;
  channel?: string;
  campaign?: string;
  fallbackUrl?: string;
}): Promise<{ deeplink: string }> => {
  const response = await fetch("/api/deeplink", {
    method: "POST",
    body: JSON.stringify(body)
  });

  return await response.json();
}

export const getPredictedRank = (exam: string, marks: string) => {
  return apiClient("", `/api/rank-predictor/${exam}?marks=${marks}`);
}

export const getStateWiseDistricts = (state: string) => {
  return apiClient("", `/api/${state}/districts`);
}

export const getInterceptorActions = (page: string, params?: ParsedUrlQuery) => {
  return apiClient(BACKEND_API, "/v1/web/navigation-flow-modal", {
    query: {
      page_params: params ? JSON.stringify(params) : "",
      page,
    }
  });
}

export const submitStudentDetails = (body: {
  mobile: string;
  name: string;
  email_id: string;
  parent_name: string;
  pin_code: string;
}) => {
  return apiClient(BACKEND_API, "/v1/payment/student-additional-details", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body)
  });
}

export const getPinCodeData = async (pincode: string) => {
  const response = await fetch(`/api/pincode/${pincode}`);
  return response.json();
}