import { DefaultSeoProps } from "next-seo";
import { STATIC_CDN_ASSETS, STATIC_CDN_IMAGES } from "./constants";

export const defaultTitle = "Doubt Solutions - Maths, Science, CBSE, NCERT, IIT JEE, NEET";
export const defaultDescription = "Doubt solutions for Maths, Science, CBSE, NCERT, IIT JEE, NEET & Class 6 to 12. Click, type question to get instant video answers solved by Doubtnut team.";

const SEO: DefaultSeoProps = {
  defaultTitle,
  themeColor: "#eb532c",
  facebook: {
    appId: "363256747382511",
  },
  twitter: {
    site: "Doubtnut",
  },
  openGraph: {
    siteName: "Doubtnut",
    type: "website",
  },
  additionalMetaTags: [
    // @ts-ignore
    { charSet: "utf-8" },
    { name: "google-site-verification", content: "cbuTRdjZEuPXTzEguelF7RFTiCMCE-k9OMX1RS4849k" },
    { property: "fb:pages", content: "144668882531783" },
    // @ts-ignore
    { name: "ir-site-verification-token", value: "-272191986" },
    { name: "mobile-web-app-capable", content: "yes" },
    { name: "google-site-verification", content: "AkiK6MfjL3CUHbsMNwd-SXGU7cQpUOCc14hEy_HhZCU" },
    { name: "msapplication-TileImage", content: `${STATIC_CDN_IMAGES}/new-favicon-32.ico` },
    { name: "msapplication-square70x70logo", content: `${STATIC_CDN_IMAGES}/new-favicon-70.ico` },
    { name: "msapplication-square150x150logo", content: `${STATIC_CDN_IMAGES}/new-favicon-150.ico` },
    { name: "msapplication-TileColor", content: "#2a394f" },
    { name: "author", content: "Doubtnut" },
    { name: "publisher", content: "Doubtnut" },
  ],
  additionalLinkTags: [
    { rel: "icon", href: `${STATIC_CDN_ASSETS}/favicon.ico`, type: "image/x-icon" },
    { rel: "apple-touch-icon", href: `${STATIC_CDN_IMAGES}/new-favicon-32.ico` },
    { rel: "apple-touch-icon", sizes: "76X76", href: `${STATIC_CDN_IMAGES}/new-favicon-76.ico` },
    { rel: "apple-touch-icon", sizes: "120x120", href: `${STATIC_CDN_IMAGES}/new-favicon-120.ico` },
    { rel: "apple-touch-icon", sizes: "152x152", href: `${STATIC_CDN_IMAGES}/new-favicon-152.ico` },
    { rel: "apple-touch-icon", sizes: "167x167", href: `${STATIC_CDN_IMAGES}/new-favicon-167.ico` },
    { rel: "apple-touch-icon", sizes: "180x180", href: `${STATIC_CDN_IMAGES}/new-favicon-180.ico` },
    { rel: "shortcut icon", href: `${STATIC_CDN_IMAGES}/new-favicon-32.ico`, type: "image/x-icon" },
    { rel: "shortcut icon", type: "image/x-icon", sizes: "16x16", href: `${STATIC_CDN_IMAGES}/new-favicon-16.ico` },
    { rel: "shortcut icon", type: "image/x-icon", sizes: "48x48", href: `${STATIC_CDN_IMAGES}/new-favicon-48.ico` },
    { rel: "shortcut icon", type: "image/x-icon", sizes: "32X32", href: `${STATIC_CDN_IMAGES}/new-favicon-32.ico` },
    { rel: "dns-prefetch", href: "https://api.doubtnut.com", crossOrigin: "true" },
    { rel: "dns-prefetch", href: "https://web-api.doubtnut.com", crossOrigin: "true" },
    { rel: "dns-prefetch", href: "https://micro.doubtnut.com", crossOrigin: "true" },
    { rel: "dns-prefetch", href: "https://static.doubtnut.com", crossOrigin: "true" },
    { rel: "dns-prefetch", href: "https://www.googletagmanager.com", crossOrigin: "true" },
    { rel: "dns-prefetch", href: "https://www.google-analytics.com", crossOrigin: "true" },
  ],
};

export default SEO;
