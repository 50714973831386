const schemaData = {
  "@context": "https://schema.org",
  "@graph": [
    {
      "@type": "Organization",
      "@id": "https://www.doubtnut.com/#organization",
      "name": "Doubtnut",
      "url": "https://www.doubtnut.com/",
      "founder": [
        {
          "@type": "Person",
          "@id": "https://www.doubtnut.com/about-us/#aditya-shankar",
          "name": "Aditya Shankar"
        },
        {
          "@type": "Person",
          "@id": "https://www.doubtnut.com/about-us/#tanushree",
          "name": "Tanushree"
        },
      ],
      "logo": {
        "@type": "ImageObject",
        "@id": "https://www.doubtnut.com/#favicon-logo",
        "inLanguage": "en",
        "url": "https://d1bwwumizb0ypj.cloudfront.net/favicon.ico",
        "contentUrl": "https://d1bwwumizb0ypj.cloudfront.net/favicon.ico",
        "width": "32px",
        "height": "32px",
        "caption": "Doubtnut"
      },
      "image": {
        "@id": "https://www.doubtnut.com/#favicon-logo"
      },
      "sameAs": [
        "http://www.wikidata.org/entity/Q63565545",
        "https://www.youtube.com/@Doubtnut",
        "https://www.youtube.com/@DoubtnutNEET",
        "https://www.youtube.com/@learnandfun-class10",
        "https://www.youtube.com/@LearnandFunClass9",
        "https://www.youtube.com/@learnandfunclass11science",
        "https://www.youtube.com/@LearnandFunClass12",
        "https://www.youtube.com/@learnandfunhumanities",
        "https://www.youtube.com/@learnandfuncommerce",
        "https://www.youtube.com/@Class10HindiMedium",
        "https://www.youtube.com/@doubtnutclass11hindimedium",
        "https://www.youtube.com/@doubtnutJEEHindimedium",
        "https://www.youtube.com/@DoubtnutClass9HindiMedium",
        "https://www.youtube.com/@vikramsingh",
        "https://www.youtube.com/@Yogeshsirbiology",
        "https://www.youtube.com/@gopalsahusirphysics",
        "https://www.youtube.com/@bhumikachauhanenglish",
        "https://www.youtube.com/@deepaksirmaths",
        "https://www.youtube.com/@DoubtnutBSEB",
        "https://www.instagram.com/doubtnut/",
        "https://t.me/doubtnut",
        "https://t.me/DoubtnutJEEHindi",
        "https://t.me/DoubtnutNEETHindi",
        "https://t.me/Learn_And_Fun_9th10th",
        "https://t.me/+Fu8-H6cN7OtjNmFl",
        "https://t.me/vikramsirhapchemistry",
        "https://t.me/Doubtnutclas10thhindimedium",
        "https://t.me/doubtnutclass9thhindimedium",
        "https://t.me/Doubtnut11th12thhindimedium",
        "https://t.me/bhumikachauhanenglishclasses",
        "https://t.me/DoubtnutBiharBoards",
        "https://chat.whatsapp.com/GpFUR9MOky260MBNPFVrED",
        "https://chat.whatsapp.com/FOLi8WPKoDbAkXuhSRqpTV",
        "https://chat.whatsapp.com/KplWRFOrKsf5v5bevV9cap",
        "https://chat.whatsapp.com/EDZzXtwKy3nIuaYaGnzpuP",
        "https://chat.whatsapp.com/KnuFTsWD1Ts9QjFW1xhcTe",
        "https://chat.whatsapp.com/C6pgxli2TQ52CJz6m0DaId",
        "https://chat.whatsapp.com/GHMWQmVCIoC68HyoakR72C",
        "https://chat.whatsapp.com/JHc0pDxPxt7EV5hUOL3hAo",
        "https://chat.whatsapp.com/LUGpNak5UIRGHXJJU0OXNb",
        "https://chat.whatsapp.com/FSp6WiRk6svJJi3vPri7J3"
      ],
    },
  ]
};

const OrgSEO = () => {
  return <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
  >
  </script>
}

export default OrgSEO;
