import SEO from "@/config/seo-config";
import { DefaultSeo } from "next-seo";
import OrgSEO from "@/components/Head/Org";
import { memo } from "react";

const BaseHead = () => {
  return (
    <>
      <DefaultSeo {...SEO} />
      <OrgSEO />
    </>
  )
}

export default memo(BaseHead);