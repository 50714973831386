import { studentIdCall } from "@/helpers/api/service";
import { decodeToken, getStudentIdLS, getUUID, setStudentIdLS } from "@/utils/auth";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  user: IUser;
  studentId: string;
}

interface IUser {
  id?: number;
  isVip?: boolean;
  mobile?: string;
  vipData?: {
    pro: {
      enabled: boolean;
    }
    course: {
      enabled: boolean;
    }
    ads: {
      banner: boolean;
      video: boolean;
      interstitial: boolean;
    }
    tuition: {
      enabled: boolean;
    }
  }
}

const initialState: AuthState = {
  user: decodeToken(),
  studentId: getStudentIdLS() as string,
};

export const setStudentId = createAsyncThunk(
  "auth/setStudentId",
  async (arg, { dispatch, getState, extra, requestId, signal, rejectWithValue }) => {
    const uuid = getUUID();
    const pageUrl = window.location.href;
    try {
      const response = await studentIdCall(uuid, pageUrl);
      const studentId = response.data?.student_id;
      setStudentIdLS(studentId);
      return studentId;
    } catch (e) {
      rejectWithValue(e);
    }
  }
)

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    resetUser: (state) => {
      state.user = {};
    },
    refreshUser: (state) => {
      state.user = decodeToken();
    },
  },
  extraReducers: {
    [setStudentId.fulfilled.type]: (state, action) => {
      const studentId = action.payload;
      state.studentId = studentId;
    },
    [setStudentId.rejected.type]: (state, action) => {
      // probably log or send api request again to server?
    },
  }
});
export default authSlice.reducer;

export const {
  setUser,
  resetUser,
  refreshUser,
} = authSlice.actions;

export const selectUser = (state: { auth: AuthState }) => state.auth.user;
export const selectStudentId = (state: { auth: AuthState }) => state.auth.studentId;
export const selectCourseEnabled = (state: { auth: AuthState }) => state.auth.user.vipData?.course.enabled;
export const selectProEnabled = (state: { auth: AuthState }) => state.auth.user.vipData?.pro.enabled;
