import { v4 as uuidv4 } from "uuid";
import { getCookieByName } from ".";

export function isWebView(headers?: any) {
  let webView = global?.window?.localStorage?.getItem("x-requested-with")?.startsWith("com.doubtnutapp");
  if (webView) {
    return true;
  }
  return String(headers?.["x-requested-with"])?.startsWith("com.doubtnutapp");
}

export function isAuthenticated() {
  return (getToken() && getRefreshToken()) ? true : false;
}
export function getUUID() {
  let a1 = localStorage.getItem("a_1");
  if (!a1) {
    a1 = uuidv4();
    localStorage.setItem("a_1", a1);
  }
  return a1
}

export function getStudentIdLS() {
  if (typeof window === "undefined") return null;
  return localStorage.getItem("student_id");
}

export function setStudentIdLS(sid: string) {
  return localStorage.setItem("student_id", sid);
}

export function getToken() {
  return getCookieByName("token") ?? global?.window?.localStorage?.getItem("token");
}

export function getRefreshToken() {
  return getCookieByName("refresh-token") ?? global?.window?.localStorage?.getItem("refresh-token");
}

export function setToken(token: string) {
  setCookie("token", token, decodeToken(token)?.exp)
  return localStorage.setItem("token", token);
}

export function setRefreshToken(refreshToken: string) {
  setCookie("refresh-token", refreshToken, decodeToken(refreshToken)?.exp)
  return localStorage.setItem("refresh-token", refreshToken);
}

export function decodeToken(authToken?: string) {
  const token = authToken || getToken();
  if (!token) {
    return {};
  }
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return {};
  }
}

export function setCookie(cookieName: string, cookieValue: string, expiry?: number) {
  if (!global?.window?.document) {
    return;
  }
  document.cookie = `${cookieName}=${cookieValue};Path=/;${expiry ? `Expires=${new Date(expiry * 1000).toUTCString()};` : ""}`;
}

export function deleteCookie(cookieName: string) {
  document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function logout() {
  if (!global?.window?.document) {
    return;
  }
  localStorage.removeItem("token");
  localStorage.removeItem("refresh-token");
  localStorage.removeItem("mobile");
  deleteCookie("token");
  deleteCookie("refresh-token");
}
