import { IVideoPlayerData } from "@/helpers/VideoPlayer/interface";
import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "..";


export interface IVideoModalState extends IVideoPlayerData{
  showModal: boolean;  
}

const initialState: IVideoModalState = {
  showModal: false,  
  video_name: ""
};

// Actual Slice
export const videoModalSlice = createSlice({
  name: "videoModal",
  initialState,
  reducers: {
    resetVideoModalIntent(state) {
      state.showModal = false;
      state.video_name = "";
      delete state.question_id;
      delete state.answer_id
    },
    updateVideoModalIntent(state, action) {
      state.showModal = true;
      state.question_id = action.payload.question_id;
      state.video_name = action.payload.video_name;
      state.answer_id = action.payload.answer_id;
    },
  },
});

export default videoModalSlice.reducer;

export const selectVideoModal = (state: AppState) => state.videoModal;
export const selectShowVideoModal = (state: AppState) => state.videoModal.showModal;

export const {
  resetVideoModalIntent,
  updateVideoModalIntent,
} = videoModalSlice.actions;
