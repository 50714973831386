import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { seminarSlice } from "./slices/seminar";
import { checkoutSlice } from "./slices/checkout";
import { videoModalSlice } from "./slices/video";
import { offCanvasSlice } from "./slices/offCanvas";
import { promoCompanionSlice } from "./slices/promoCompanion";
import { authSlice } from "./slices/auth";
import { listenerMiddleware } from "./listenerMiddleware";

export function makeStore() {
  return configureStore({
    reducer: {
      [authSlice.name]: authSlice.reducer,
      [seminarSlice.name]: seminarSlice.reducer,
      [checkoutSlice.name]: checkoutSlice.reducer,
      [videoModalSlice.name]: videoModalSlice.reducer,
      [offCanvasSlice.name]: offCanvasSlice.reducer,
      [promoCompanionSlice.name]: promoCompanionSlice.reducer,
    },
    // fixing error in console -> A non-serializable value was detected in the state
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat(listenerMiddleware.middleware)
    },
  });
}

const appStore = makeStore();

export type AppState = ReturnType<typeof appStore.getState>

export type AppDispatch = typeof appStore.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>

export default appStore;
