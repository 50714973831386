// import { decodeToken, logout } from '@/Hel';
import { MICRO_API } from "@/config/constants";
import { removeEmptyKeys } from "@/utils";
import { decodeToken, getRefreshToken, getToken, logout, setRefreshToken, setToken } from "@/utils/auth";
import { IHeaderResponse } from "./interfaces";

const getTokenHeaders = (headers: any) => {
  if (!headers) {
    return;
  }
  const resp: IHeaderResponse = {};
  const token = headers.get("dn-x-auth-token");
  const refreshToken = headers.get("dn-x-auth-refresh-token");
  if (token) {
    resp.token = token
  }
  if (refreshToken) {
    resp["refresh-token"] = refreshToken;
  }
  return resp;
}

const saveHeaders = (headers: any) => {
  if (!headers || !global.window) {
    return;
  }
  const token = headers.get("dn-x-auth-token");
  const refreshToken = headers.get("dn-x-auth-refresh-token");
  if (token) {
    try {
      setToken(token);
      if (decodeToken(token).isVip) {
        window.googletag = window.googletag || ({} as typeof window.googletag);
        window.googletag.cmd = window.googletag.cmd || [];
        googletag.cmd.push(() => {
          googletag.destroySlots();
        });
      }
    } catch (e) { }
  }
  if (refreshToken) {
    setRefreshToken(refreshToken);
  }
}

export async function apiClient(host: string, url: string, options: any = {}): Promise<any> {
  const token = this?.token || getToken();
  let gw = "";
  if (token && (host === MICRO_API)) {
    gw = "/api";
  }

  options.headers = options.headers ?? {};
  options.headers["Content-Type"] = "application/json";
  if (token) {
    options.headers["x-auth-token"] = token;
  }
  removeEmptyKeys(options.headers);

  if (options.query) {
    let params = new URLSearchParams();
    for (const key in options.query) {
      if (options.query[key] === undefined) continue;
      if (Array.isArray(options.query[key])) {
        options.query[key].forEach((value: string) => {
          params.append(key, value);
        });
      } else {
        params.append(key, options.query[key]);
      }
    }
    const qs = params.toString();
    url += `?${qs}`;
  }

  try {
    let resHeaders: IHeaderResponse = {};
    const response = await fetch(`${host}${gw}${url}`, options);

    if (!response.ok) {
      if ((response.status == 401) && options.headers?.["x-auth-refresh-token"]) {
        logout();
        resHeaders.token = "";
        resHeaders["refresh-token"] = "";
        const resData = await response.json();
        return { data: resData?.data ?? resData, meta: resData?.meta, msg: "RESPONSE NOT OK", headerRes: resHeaders };
      }

      const refreshToken = this?.refreshToken || getRefreshToken();
      if ((response.status == 401) && !options.headers?.["x-auth-refresh-token"] && refreshToken) {
        options.headers["x-auth-refresh-token"] = refreshToken;
        return apiClient.bind(this)(host, url, options);
      }
      const resData = await response.json();
      return { data: resData?.data ?? resData, meta: resData?.meta, msg: "RESPONSE NOT OK" };
    }
    if (`${response.status}`.startsWith("2")) {
      saveHeaders(response.headers);
      const tokenHeaders = getTokenHeaders(response.headers);
      if (tokenHeaders) {
        resHeaders = tokenHeaders;
      }
    }
    const resData = await response.json();
    return { data: resData?.data ?? resData, meta: resData?.meta, headerRes: resHeaders };
  } catch (err) {
    return { data: null, msg: "API FAILED", err: err?.toString() };
  }
}
