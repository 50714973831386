import { createSlice } from "@reduxjs/toolkit";
import { IVideoPromoCompanion } from "@/components/VideoPlayer/interfaces";
import { AppState } from "..";

const initialState: { data: IVideoPromoCompanion | null; videoProgress: number; ctaClicked: boolean } = {
  data: null,
  videoProgress: 0,
  ctaClicked: false,
};

// Actual Slice
export const promoCompanionSlice = createSlice({
  name: "promoCompanion",
  initialState,
  reducers: {
    setPromoCompanion(state, action) {
      state.data = action.payload;
      state.videoProgress = 0;
    },
    setPromoVideoProgress(state, action) {
      state.videoProgress = action.payload;
    },
    setPromoCTAClicked(state, action) {
      state.ctaClicked = action.payload;
    },
  },
});

export default promoCompanionSlice.reducer;

export const selectPromoCompanion = (state: AppState): IVideoPromoCompanion | null => state.promoCompanion.data;
export const selectPromoVideoProgress = (state: AppState): number => state.promoCompanion.videoProgress;
export const selectPromoCTAClicked = (state: AppState): boolean => state.promoCompanion.ctaClicked;

export const {
  setPromoCompanion,
  setPromoVideoProgress,
  setPromoCTAClicked,
} = promoCompanionSlice.actions;
